import { effect, inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Video } from '../../video/entities/video.interface';
import { LectureData } from '../entities/lecture-data.interface';
import { UploadUtil } from '../entities/upload-util';
import { VideoData } from '../entities/video-data.interface';

@Injectable({
    providedIn: 'root',
})
export class UploadService {
    private router = inject(Router);

    private maxSteps = 4;
    public lastCompletedStep = signal(0);
    public activeStep = signal(1);
    public readonly videoData = signal<VideoData>({
        thumbnail: {},
        presentation: {},
    });
    public readonly lectureData = signal<LectureData>({
        date: new Date(),
        categoryIds: [],
        authorIds: [],
        public: false,
        videos: [],
        draft: true
    });

    constructor() {
        effect(() => this.router.navigate([ '/upload-video', 'step', this.activeStep() ], {
            queryParamsHandling: 'preserve'
        }));
    }

    public nextStep() {
        this.lastCompletedStep.set(Math.max(this.activeStep(), this.lastCompletedStep()));
        this.moveToStep(this.activeStep() + 1);
    }

    public previousStep() {
        this.moveToStep(this.activeStep() - 1);
    }

    public moveToStep(id: number) {
        const idInRange = Math.min(this.maxSteps, Math.max(id, 1));

        if (idInRange === 1 || this.lastCompletedStep() >= id - 1) {
            this.activeStep.set(id || 1);
        }
    }

    public reset() {
        this.lastCompletedStep.set(0);
        this.activeStep.set(1);
        this.videoData.set({
            thumbnail: {},
            presentation: {},
        });
        this.lectureData.set({
            date: new Date(),
            categoryIds: [],
            authorIds: [],
            public: false,
            videos: [],
            draft: true
        });
    }

    public async setLectureData(lecture: Video) {
        this.lectureData.set({
            lectureId: lecture.id,
            slug: lecture.slug,
            eventId: lecture.event?.id,
            title: lecture.title,
            description: lecture.description,
            type: lecture.type,
            date: lecture.date ? new Date(lecture.date) : undefined,
            languageId: lecture.language,
            categoryIds: lecture.categories.map(c => c.id),
            authorIds: lecture.authors?.map(a => a.id) ?? [],
            duration: lecture.duration,
            videos: lecture.videos?.map(v => ({
                video_format: 'mp4',
                uploaded_url: v.video,
                resolution: v.resolution
            })) ?? [],
            thumbnail: lecture.thumbnail ?
                await UploadUtil.convertToBase64(await UploadUtil.loadFile(lecture.thumbnail))
                : undefined,
            public: !!lecture.public,
            draft: !!lecture.draft
        });
    }

    public importLectureData(video: Video) {
        this.lectureData.set({
            ...this.lectureData(),
            slug: video.slug,
            eventId: video.event?.id,
            type: video.type,
            date: video.date ? new Date(video.date) : undefined,
            languageId: video.language,
            categoryIds: video.categories.map(c => c.id),
        });
    }

}
